<template>
    <div>
      <div class="app-modal__box">
        <div class="app-modal__in">
          <div class="app-modal__header d-flex f-between p-5 m-title-modal">
            <p class="large--title m-0">{{
                $t("message.edit_m", { m: $t("message.task"), })  +'   № ' + form.id
              }}</p>
            <div>
              <crm-store-update-close
                  :permission="$options.name"
                  :button_type="'store'"
                  :loading="loadingButton"
                  @c-submit="submit(true)"
                  @c-close="close()"
              ></crm-store-update-close>
            </div>
          </div>
        </div>
  
        <div class="app-modal__fulls p-5">
          <el-form ref="form" :model="form" :rules="rules" >
  
          <el-row :gutter="20">
            <el-col :span="18">
              <div class="wrap-all d-flex f-between f-wrap">
                <div class="w-100">
                  <div class="gc-card rounded-sm mb-4 p-3 p-relative">
                    <div class="w-100 align-center px-3">
                      <el-form-item prop="name" class="label_mini">
                        <el-input
                          class="mt-2"
                          autosize
                          type="textarea"
                          :placeholder="$t('message.name')"
                          v-model="form.name">
                        </el-input>
                      </el-form-item>
                    </div>
                    <div class="mr-3 ml-4">
                      <span class="font-bold d-block"> {{ $t('message.deadline') }} </span>
                    </div>
  
                    <div class="data-task">
                        <div class="p-2">
                          <el-date-picker
                            v-model="form.date"
                            size="medium"
                            type="datetimerange"
                            range-separator="/"
                            :start-placeholder="$t('message.from')"
                            :end-placeholder="$t('message.to')"
                            format="yyyy-MM-dd HH:mm"
                            value-format="yyyy-MM-dd HH:mm">
                          </el-date-picker>
                        </div>
                        <div class="data-task-cheek">
                          <div class="my-button ml-4" >
                            <el-date-picker
                                class="sroc"
                                v-model="form.finish_date"
                                type="date"
                                :disabled="!finishDateCheck"
                                :placeholder="$t('message.date')"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd">>
                            </el-date-picker>
                            <span class="p-1 rounded" :class="finishDateCheck ? 'task_done' : (form.status === 4 ? 'task_for_check' : 'task_in_process_1')"> {{ finishDateCheck ? $t("message.completed") : ((taskPercentage === 100) ? $t("message.check_task") : $t("message.in_process"))}}  </span>
                          </div>
                          <el-checkbox v-if="form.status === 4 && (authUser.is_super_admin || (authUser.id === (form.user ? form.user.id : 0)))" class="mt-2 ml-4 mr-0" size="mini" v-model="finishDateCheck" :label="$t('message.task_checked')" border></el-checkbox>
                          <el-checkbox v-if="miniTasksCount === 0" class="mt-2 ml-4" size="mini" v-model="taskToChecking" :label="$t('message.ready_to_check')" border></el-checkbox>
                        </div>
                    </div>
                      <!-- end data-task -->
  
                    <div class="gc-card__body px-3 py-4">
                      <el-row :gutter="20">
                        <el-col :span="12">
                          <el-form-item :label="$t('message.exhibition')" prop="exhibition_id" class="label_mini mb-0">
                            {{ form.exhibition_id }}
                          </el-form-item>
                        </el-col>
                      </el-row>
                      
                      <div class="user-metca mt-5">
                        <div class="gc-card-users__info mt-4 mr-3" v-if="form.participants && form.participants.length > 0">
                          <span class="font-bold mb-2 d-block"> {{ $t('message.participants') }} </span>
  
                          <div class="users__info">
                            <div class="task_user_give">
                              <div class="itme-userss">
                                  <el-popover
                                      placement="top"
                                      popper-class="p-0 myir"
                                      width="300"
                                      trigger="click">
                                      <div class="click-block">
                                          <div class="top-u-click">
                                              <img class="task_user_give_img" :src="authUser.avatar ? authUser.avatar : '/img/flag/employee.svg'" alt="">
                                              <div class="tt-name">
                                                  <div>{{ authUser.name }}</div>
                                                  <span>{{ authUser.email ?  authUser.email : ''}}</span>
                                              </div>
                                          </div>
                                          <ul>
                                              <li>
                                                  <span><i class="el-icon-warning-outline"></i>ID: {{ authUser.id }}</span>
                                              </li>
                                              <li>
                                                  <span><i class="el-icon-mobile-phone"></i>Tel: {{ authUser.phone }}</span>
                                              </li>
                                          </ul>
                                      </div>
                                      
                                      <el-button slot="reference">
                                          <div class="task_user_give_names"><img class="task_user_give_img" :src="authUser.avatar ? authUser.avatar : '/img/flag/employee.svg'" alt=""></div>
                                      </el-button>
                                  </el-popover>
                              </div>
                            </div>
                            <i class="el-icon-arrow-right ml-1 mr-1"></i>
                            <div class="d-flex task_user_accept">
                              <div class="itme-userss">
                                  <el-popover
                                      placement="top"
                                      popper-class="p-0 myir"
                                      width="300"
                                      v-for="participant in users.filter(el => form.participants.includes(el.id)) " :key="participant.id"
                                      trigger="click">
                                      <div class="click-block">
                                          <div class="top-u-click">
                                              <img class="task_user_give_img" :src="participant.avatar ? participant.avatar : '/img/flag/employee.svg'" alt="">
                                              <div class="tt-name">
                                                  <div>{{ participant.name }}</div>
                                                  <span>{{ participant.email ? participant.email : ''}}</span>
                                              </div>
                                          </div>
                                          <ul>
                                              <li>
                                                  <span><i class="el-icon-warning-outline"></i>ID: {{ participant.id }}</span>
                                              </li>
                                              <li>
                                                  <span><i class="el-icon-mobile-phone"></i>Tel: {{ participant.phone }}</span>
                                              </li>
                                          </ul>
                                          <div class="delet">
                                              <el-button size="small" class="w-100" type="danger" @click="deleteParticipant(participant.id)">{{ $t('message.delete_participant_from_list') }}</el-button>
                                          </div>
                                      </div>
                                      
                                      <el-button slot="reference">
                                          <img class="task_user_give_img" :src="participant.avatar ? participant.avatar : '/img/flag/employee.svg'" alt="">
                                      </el-button>
                                  </el-popover>
                              </div>
                            </div>
                          </div>
                        </div>
  
                        <div class="u-clearfix js-card-detail-labels-list mt-4" v-if="form.labels && form.labels.length > 0">
                          <div class="font-bold mb-2 d-block">{{ $t('message.labels') }}</div>
  
                          <span
                              class="card-label mod-card-detail"
                              title=""
                              :class="label.color_id ? 'color_'+label.color_id : 'color_1'"
                              v-for="label in form.labels" :key="label.id"
                              >
                            <span class="label-text"> {{ label.name }} <i class="hover-dis el-icon-circle-close" @click="removeLabelFromTask(label)"></i> </span>
                          </span>
  
                        </div>
                      </div>
                      <!-- end user-metca -->
                      <!-- ALL UPLOADED FILES -->
                      <el-row :gutter="20" class="p-2" v-if="[...updateFile, ...form.files] && [...updateFile, ...form.files].length > 0">
                        <h3>{{$t('message.attachments')}}</h3>
                        <el-col :span="12" v-for="(file, index) in [...updateFile, ...form.files]" :key="file.uid">
                          <div class="mb-2 d-flex" style="border: 1px solid #dee3e7;" :class="(file.name === form.cover_image) ? 'mm-image_covered' : ''">
                            <div class="mm-image-container" @click="showImage(file)">
                              <el-image v-if="['image/png', 'image/jpeg', 'jpg', 'jpeg', 'png'].includes(file.type || (/[^.]*$/.exec(file.name)[0]))" 
                                style="max-width:100%; max-height:100%; position: relative; top: 50%; transform: translateY(-50%);" 
                                class="p-1"
                                :src="file.url"
                                fit="fill">
                              </el-image>
                              <div v-else class="text-center">
                                <i class="el-icon-document-copy mt-2" style="font-size: 50px;"></i>
                                <span class="d-block mt-2 font-weight-bold" >{{/[^.]*$/.exec(file.name)[0]}}</span>
                              </div>
                            </div>
                            <div class="ml-2" >
                              <h3>{{file.name }}</h3>
                              <div class="d-flex">
                                <el-button v-if="['image/png', 'image/jpeg', 'jpg', 'jpeg', 'png'].includes(file.type || (/[^.]*$/.exec(file.name)[0]))"
                                  class="mr-3" 
                                  @click="setCoverImage(file)"
                                  type="primary" plain icon="el-icon-postcard" size="medium">
                                  {{ (file.name === form.cover_image) ? $t('message.remove_cover') : $t('message.set_cover') }}
                                </el-button>
                                <a :href="file.url" :download="file.name" class="mr-3"> 
                                  <el-button @click="downloadFile(file)" type="primary" plain icon="el-icon-download" size="medium"></el-button>
                                </a>
                                <el-button @click="file.id ? destroyFile(file, index, updateFile) : deleteFile()" class="mr-3" type="danger" plain icon="el-icon-delete-solid" size="medium">
                                </el-button>
                              </div>
                              
                            </div>
                            
                          </div>
                        </el-col>
                      </el-row>
                    </div>
                  </div>
                  <div class="task-check-tasks d-block w-100">
                    <div class="gc-card rounded-sm mb-4 p-relative p-5">    
                      <span class="font-bold mb-2 d-block">
                        <i class="el-icon-notebook-2"></i>  {{ $t("message.message_m") }}
                      </span> 
                      <div class="w-100">
                        <div class="mm-mini-task-comment w-100">
                          <div v-for="(comment, index) in form.comments" :key="'com_'+index">
                            <div class="mm-mini-comment-container" 
                              :class="[((authUser.id === comment.user_id) ? ['mm-left-15', 'darker'] : 'mm-right-15'), (new_form_comment.index === index) ? 'mm-back-editing' : '' ]">
                              <div v-if="authUser.id === comment.user_id" @click="editFormComment(comment, index)" class="mm-mini-comment-edit-icon">
                                <i class="el-icon-edit-outline"></i>
                              </div>
                              <span class="d-block">{{ comment.comment }}</span>
                              <span :class="(authUser.id === comment.user_id) ? 'time-left' : 'time-right'">
                                <span v-if="users.find(el => el.id === comment.user_id)" class="mr-4">
                                  <i class="el-icon-user"></i> {{ users.find(el => el.id === comment.user_id).name + ', ' }} 
                                </span>
                                {{ comment.updated_at }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex">
                          <el-input
                            ref="FormCommentRef"
                            class="mt-2"
                            autosize
                            type="textarea"
                            :placeholder="$t('message.please_input')"
                            v-model="new_form_comment.comment">
                          </el-input>
                          <i @click="saveFormComment()" class="el-icon-s-promotion mm-send-icon"></i>
                          <i v-if="new_form_comment.index >= 0" @click="cancelSavingFormComment()" class="el-icon-close mm-send-icon"></i>
                        </div>
                      </div>   
                    </div>
                  </div>
                </div>
  
                <!-- Check List Start -->
                <div class="task-check-tasks d-block w-100" v-for="(list, list_index) in form.checkLists" :key="list.number">
                  <div class="gc-card rounded-sm mb-4 p-relative p-5">  
                    
                    <!-- CheckList Name Update Delete  -->
                    <div class="mb-2 d-flex align-center">
                      <i class="el-icon-tickets mr-2"></i>
                      <div class="d-flex w-100">
                        <div v-if="list.updating" class="w-100 mt-2 p-5 mm-updating-back-color">
                          <el-input
                            ref="checkListUpdate"
                            type="textarea"
                            :autosize="{ minRows: 2}"
                            :placeholder="$t('message.please_input')"
                            v-model="newTask">
                          </el-input>
                          <div class="d-flex justify-content-between">
                            <div class="text-left w-100 mt-2">
                              <el-button type="primary" plain size="medium" @click="saveCheckList(list)"> {{ $t('message.save') }}</el-button>
                              <el-button type="info" plain size="medium" @click="closeCheckList(list)">{{ $t('message.cancel') }}</el-button>
                            </div>
                          </div>
                        </div>
                        <span v-if="!list.updating" class="font-bold pointer" @click="updateCheckList(list)" > {{ list.name }} </span>
                        <div  v-if="!list.updating" class="ml-auto d-flex">
                          <div
                            class="change-form ml-auto pointer"
                            ref="showChangeContentBtn"
                            @click="updateCheckList(list)"
                          >
                            {{ $t("message.update") }}
                          </div>
                          <div
                            class="change-form ml-5 pointer"
                            ref="showChangeContentBtn"
                            @click="deleteCheckList(list)"
                          >
                            {{ $t("message.delete") }}
                          </div>
                        </div>
                        
                      </div>
                    </div>
  
                    <!-- Checked Percentage -->
                    <div>
                      <el-progress
                        :text-inside="true"
                        :stroke-width="18"
                        :percentage="list.percentage"
                        status="success"
                      ></el-progress>
                    </div>
                    <!-- Mini Tasks Start -->
                    <div v-for="(miniTask, index) in list.miniTasks" :key="miniTask.number" id="miniTaskId" @click="parentClicked($event, miniTask)" class="mm-hover-parent item-checkbox-task mt-2" >
                      <!-- Mini Tasks Checkbox Show -->
                      <div v-if="!miniTask.updating" class="checkbox-task">
                        <div>
                          <input type="checkbox" :id="list_index+'_miniTask_'+index" @change="checkListPercentage(list.number)" :name="list_index+'_miniTask_'+index" v-model="miniTask.is_done">
                          <label :for="list_index+'_miniTask_'+index" v-html="urlify(miniTask.text)" :class="miniTask.is_done ? 'mm-line-through' : ''" class="checkbox-text ml-2" ></label>
                          <div v-if="miniTask.fileList.length > 0 || miniTask.users.length > 0 || miniTask.date.length > 0" 
                            class="mt-2 mm-mini-additional-info">
                              <i v-for="user in users.filter(el => miniTask.users.includes(el.id))" :key="user.id" class="el-icon-user mr-2"> 
                                {{ user.name }}
                              </i>
                              <i v-for="file in miniTask.fileList" :key="file.uid" @click="showImage(file)" class="el-icon-picture-outline pointer mr-2"> 
                                {{ file.name }}
                              </i>
                              <span v-if="(miniTask.date && miniTask.date.length > 0)" style="font-size: 13px; align: right;"> 
                                <i class="el-icon-time"></i> 
                                {{ (miniTask.date && miniTask.date.length > 0) ? miniTask.date[0] + ' / ' + miniTask.date[1] : '' }} 
                              </span>
                          </div>
  
                        </div>
                      </div>
                      
                      <!-- Mini Tasks Edit Buttons Start -->
                      <div class="checkbox-task-delet">
                        <div v-if="!miniTask.updating" class="mm-hover-show">
                          <el-popover
                            placement="top"
                            :title="$t('message.users')"
                            trigger="click">
                            <div class="mb-5">
                                <el-transfer
                                  :titles="[$t('message.others'), $t('message.responsible')]" 
                                  :props="{key: 'id', label: 'name'}"
                                  v-model="miniTask.users"
                                  filterable
                                  :filter-placeholder="$t('message.search')"
                                  :data="JSON.parse(JSON.stringify(users))">
                                </el-transfer>
                            </div>
                            <i slot="reference" class="el-icon-user pointer update-i"></i>
                          </el-popover>
  
                          <el-popover
                            width="430"
                            :title="$t('message.attachments')"
                            placement="top"
                            @show="updateMiniTaskFiles(miniTask)"
                            trigger="click">
                            <div class="mb-5 w-100">
                              <el-upload
                                class="upload-demo"
                                action="/"
                                :accept="fileAccepts"
                                :auto-upload="false"
                                :on-change="updateFileInList"
                                :on-remove="removeUpdateFileFromList"
                                :on-preview="showImage"  
                                :file-list="miniTask.fileList"
                                list-type="picture">
                                <el-button size="small" type="primary">Click to upload</el-button>
                              </el-upload>
  
                            </div>
                            <i slot="reference" class="el-icon-document-add pointer update-i"></i>
                          </el-popover>
  
                          <el-popover
                            placement="top"
                            :title="$t('message.deadline')"
                            trigger="click">
                            <div class="mb-5 w-100">
                              <el-date-picker
                                v-model="miniTask.date"
                                type="datetimerange"
                                range-separator="/"
                                start-placeholder="Start date"
                                end-placeholder="End date"
                                format="yyyy-MM-dd HH:mm"
                                value-format="yyyy-MM-dd HH:mm"
                              >
                              </el-date-picker>
                            </div>
                            <i slot="reference" class="el-icon-time pointer update-i"></i>
                          </el-popover>
  
                          <el-popover
                            placement="top"
                            width="500"
                            :title="$t('message.comment')"
                            @after-enter="miniCommentScrollAndFocus(index)"
                            trigger="click">
                            <div class="w-100">
                              <div :id="'miniTaskCommentContainer_'+index" class="mm-mini-task-comment w-100">
                                <div v-for="(min_comment, com_index) in miniTask.comments" :key="'com_'+com_index">
                                  <div class="mm-mini-comment-container" 
                                    :class="[(authUser.id === min_comment.user_id) ? ['mm-left-15', 'darker'] : 'mm-right-15', (miniTaskNewComment.index === com_index) ? 'mm-back-editing' : '' ] "
                                    >
                                    <div v-if="authUser.id === min_comment.user_id" @click="editMiniTaskComment(min_comment, com_index)" class="mm-mini-comment-edit-icon">
                                      <i class="el-icon-edit-outline"></i>
                                    </div>
                                    <span class="d-block">{{ min_comment.comment }}</span>
                                    <span :class="(authUser.id === min_comment.user_id) ? 'time-left' : 'time-right'">
                                      <span v-if="users.find(el => el.id === min_comment.user_id)" class="mr-4">
                                        <i class="el-icon-user"></i> {{ users.find(el => el.id === min_comment.user_id).name + ', ' }} 
                                      </span>
                                      {{ min_comment.updated_at }}
                                      
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="d-flex">
                                <el-input
                                  ref="MiniTaskNewCommentRef"
                                  class="mt-2"
                                  autosize
                                  type="textarea"
                                  :placeholder="$t('message.please_input')"
                                  v-model="miniTaskNewComment.comment">
                                </el-input>
                                <i @click="saveMiniTaskComment(miniTask, index)" class="el-icon-s-promotion mm-send-icon"></i>
                                <i v-if="miniTaskNewComment.index >= 0" @click="cancelSavingMiniTaskComment(miniTask)" class="el-icon-close mm-send-icon"></i>
                              </div>
                            </div>
                            <el-badge slot="reference" is-dot :hidden="(!miniTask.comments || miniTask.comments.length <= 0)">
                              <i class="el-icon-s-comment pointer update-i"></i>
                            </el-badge>
                          </el-popover>
  
                          <i class="el-icon-edit-outline update-i" @click="updateMiniTask(miniTask)"></i>
                          <i class="el-icon-delete" @click="deleteMiniTask(list, index)"></i>  
                        </div>
                      </div>
                      <!-- Mini Tasks Edit Buttons End -->
                      
                      <!-- Update Mini Tasks Text -->
                      <div v-if="miniTask.updating" class="w-100 mt-2 p-5 mm-updating-back-color">
                        <el-input
                          ref="miniTaskInput"
                          type="textarea"
                          :autosize="{ minRows: 2}"
                          :placeholder="$t('message.please_input')"
                          v-model="newMiniTask.text">
                        </el-input>
                        <div class="d-flex justify-content-between">
                          <div class="text-left w-100 mt-2">
                            <el-button type="primary" plain size="medium" @click="updateCurrentMiniTask(miniTask)"> {{ $t('message.save') }}</el-button>
                            <el-button type="info" plain size="medium" @click="closeUpdateMiniTask(miniTask)">{{ $t('message.cancel') }}</el-button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Mini Tasks End -->
                   
                    <!-- Start Add Mini Task Component -->
                    <div v-if="list.addingMiniTask" class="w-100 mt-2 p-5 mm-updating-back-color">
                      <el-input
                        ref="miniTaskInput"
                        type="textarea"
                        :autosize="{ minRows: 2}"
                        :placeholder="$t('message.please_input')"
                        v-model="newMiniTask.text">
                      </el-input>
                      <div class="d-flex justify-content-between">
                        <div class="text-left w-100 mt-2">
                          <el-button type="primary" plain size="medium" @click="updateMiniTaskList(list)"> {{ $t('message.save') }}</el-button>
                          <el-button type="info" plain size="medium" @click="list.addingMiniTask = false">{{ $t('message.cancel') }}</el-button>
                        </div>
                        <div class="text-right mt-2 w-100">
                            <el-popover
                              placement="top"
                              :title="$t('message.users')"
                              trigger="click">
                              <div class="mb-5">
                                <el-transfer
                                  :titles="[$t('message.others'), $t('message.responsible')]" 
                                  :props="{key: 'id', label: 'name'}"
                                  v-model="newMiniTask.users"
                                  filterable
                                  :filter-placeholder="$t('message.search')"
                                  :data="JSON.parse(JSON.stringify(users))">
                                </el-transfer>
                              </div>
                              <el-button class="ml-2" slot="reference" type="success" plain icon="el-icon-user"> <i class="el-icon-plus"></i> </el-button>
                            </el-popover>
  
                            <el-popover
                              width="430"
                              :title="$t('message.attachments')"
                              placement="top"
                              trigger="click">
                              <div class="mb-5 w-100">
                                <el-upload
                                  class="upload-demo"
                                  action="/"
                                  :accept="fileAccepts"
                                  :on-change="setFileInList"
                                  :on-remove="removeFileFromList"
                                  :on-preview="showImage"
                                  :auto-upload="false"
                                  :file-list="[]"
                                  list-type="picture">
                                  <el-button size="small" type="primary">Click to upload</el-button>
                                </el-upload>
  
                              </div>
                              <el-button class="ml-2" slot="reference" type="primary" plain icon="el-icon-document-add"> {{$t('message.file')}} </el-button>
                            </el-popover>
  
                            <el-popover
                              placement="top"
                              :title="$t('message.deadline')"
                              trigger="click">
                              <div class="mb-5 w-100">
                                <el-date-picker
                                  v-model="newMiniTask.date"
                                  type="datetimerange"
                                  range-separator="/"
                                  start-placeholder="Start date"
                                  end-placeholder="End date"
                                  format="yyyy-MM-dd HH:mm"
                                  value-format="yyyy-MM-dd HH:mm"
                                >
                                </el-date-picker>
                              </div>
                              <el-button class="ml-2" slot="reference" type="warning" plain icon="el-icon-time">{{$t('message.date')}}</el-button>
                            </el-popover>
                        </div>
                      </div>
                    </div>
                    <!-- End Add Mini Task Component -->
                    
                    <!-- Add Mini Task Button -->
                    <div v-if="!list.addingMiniTask" class="text-right">
                      <el-button 
                        @click="addMiniTask(list)"
                        class="mt-2 ml-2 "
                        type="primary"
                        plain
                        icon="el-icon-s-help">
                        {{ $t("message.add_item") }}
                      </el-button>
                    </div>
  
                  </div>
                </div>
                <!-- Check List Start -->
  
              </div>
            </el-col>
          </el-row>
              
        </el-form>
  
        </div>
      </div>
    </div>
  </template>        
  <script>

  
  import { mapGetters, mapActions } from "vuex";
  import drawer from "@/utils/mixins/drawer";
  import form from "@/utils/mixins/form";
  import show from "@/utils/mixins/show";
  import task from "@/utils/mixins/views/task";
  
  export default {
    name: 'task_modal',
    mixins: [form, drawer, task, show],
 
    props: {
      board_id:{
        default: null
      },
      selected_deal:{
        default: Object
      }
    },
    data() {
      return {
        drawerDeleteTask: false,
        taskToChecking: false,
        drawerCreateCompany: false,
        drawerCreateClient: false,
        drawerCreateDeal: false,
        dealCreate: true,
        comment: "",
        company_id: null,
        client_id: null,
        labels: false,
        checkListForMiniTask: null,
        deal_id: null,
        finishDateCheck: false,
        addingTask: false,
        fileList: [],
        updateFile: [],
        taskDates: [],
        taskPercentage: 0,
        miniTasksCount: 0,
        fileAccepts: "image/png, image/jpeg, application/pdf, .doc,.docx,application/msword,.xls,.xlsx,application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain,",
      };
    },
    computed: {
        ...mapGetters({
            users: "users/inventory", 
            authUser: "auth/user",
            rules: "tasks/rules",
            model: "tasks/model",
            columns: "tasks/columns",
            mode: "MODE"
        }),
        leftVersion () {
          return this.$store.getters.LEFTVERSION
        },
    },
    watch: {
        finishDateCheck(val) {
          if(val){
            if(this.finish_date){
              this.form.finish_date = this.finish_date;
            }else{
               this.form.finish_date = this.formatDate(new Date());
            }
          }else{
            this.form.finish_date = ''
          }
        },
        form: {
            handler: async function(newVal, oldVal) {
                this.taskPercentage = 0;
                let completed = 0;
                let uncompleted = 0;
                this.form.checkLists.forEach(check_list => {
                  check_list.miniTasks.forEach(mini_task => {
                    if(mini_task.is_done){
                      completed += 1
                    }else{
                      uncompleted += 1
                    }
                  });
                });
                this.miniTasksCount = completed + uncompleted;
                if((completed + uncompleted) === 0){
                  this.taskPercentage = 0;
                }else{
                  this.taskPercentage = 100*completed/(completed + uncompleted);
                }
                if(this.taskPercentage === 100 && this.miniTasksCount > 0){
                  this.form.status = 4;
                }
                if(this.taskPercentage < 100 && this.miniTasksCount > 0){
                  this.form.status = 1;
                }
                if(this.form.status === 4){
                  this.taskToChecking = true;
                }
            },
            deep: true,
            immediate: true
        },
        taskToChecking: {
            handler: async function(newVal, oldVal) {
               if(newVal){
                 this.form.status = 4;
               }else{
                 this.finishDateCheck = false;
                 this.form.status = 1;
               }
            },
            deep: true,
            immediate: true
        },
    },
    created() {
      this.fetchData();
    },
    methods: {
      ...mapActions({
          updateUsers: "users/inventory", 
          save: "tasks/update",
          show: "tasks/show",
          saveFiles: "tasks/storeFiles",
          empty: "tasks/empty",
          downloadFileFromStorage: "tasks/downloadFile",
          deleteFile: "tasks/deleteFile",
          archivateTask: "tasks/archivateTask",
          updateDealTasks: "deals/dealTasks",
          updateDealsList: "boards/index",
      }),
  
      destroyFile(file, index, fileList){
        if(file.id){
          this.deleteFile({task_id: this.form.id, file: file.name})
            .then(res => {
              this.form.files = this.form.files.filter(item => item.id != file.id);
            });
        }else{
          fileList.splice(index, 1);
        }
        if(file.name === this.form.cover_image){
          this.form.cover_image = '';
        }
      },
  
      async fetchData() {
        if (!this.loadingData) {  
            this.loadingData = true;
            await this.updateUsers(); 
            await this.show(this.$route.params.task_id).then(res => { 
                this.form.checkLists.map((list, index) => {
                    list.number = index + 1;
                    let checked = list.miniTasks.filter(item => item.is_done === true).length;
                    let count = list.miniTasks.length;
                    if(count > 0){
                      list.percentage = Math.round(checked * 100/count);
                    }else{
                      list.percentage = 0;
                    }
                  });
                
                this.form.checkLists.map((list, index) => list.miniTasks.map((task ,key) => {
                  task.checkListNumber = list.number;
                  task.number = key + 1;
                }));
                if(this.form.finish_date){
                  this.finish_date = this.form.finish_date;
                  this.finishDateCheck = true;
                }
                this.form.comments.map((item, index) => item.number = index + 1);
                this.company_id = this.form.company_id;
                this.client_id = this.form.client_id;
                this.taskDates.push(this.form.begin_date);
                this.taskDates.push(this.form.end_date);
  
                this.loadingData = false              
            }).catch(err => {
                this.loadingData = false
            });
        }
      },
  
      archivatingTask(task_id) {
        this.$confirm(
            this.$t('message.do_you_really_want_to_do_this'),
            this.$t('message.warning'), {
              confirmButtonText: this.$t('message.yes'),
              cancelButtonText: this.$t('message.no'),
              type: "warning"
            }
          )
          .then(() => {
            this.archivateTask(task_id).then(() => {
            this.close(true);
            this.parent().listChanged();
  
            });
            this.$message({
              type: "success",
              message: this.$t('message.operation_completed')
            });
          })
          .catch(() => {
            this.$message({
              type: "warning",
              message: this.$t('message.operation_canceled')
            });
          });
      },
      deleteTaskClose(val) {
        this.drawerDeleteTask = false;
        if(val){
          this.close();
          this.parent().listChanged();
        }
      },
      
      
    },
  };
  </script>
  <style>
    .task-select-plus-icon {
      font-size: 34px; 
      margin: 2px 10px 
    }
    .task_in_process_1 {
      background-color: rgb(255, 102, 0);
      color: white;
    }
    .task_for_check {
      background-color: rgb(50, 127, 226);
      color: white;
  
    }
    .task_done {
      background-color: rgb(92, 196, 92);
      color: white;
    }
    .wu-100 .el-upload{
      width: 100%;
    }
    .text-contents{
      min-height: 33px;
      display: block;
      resize: vertical;
      padding: 5px 15px;
      line-height: 1.5;
      box-sizing: border-box;
      width: 100%;
      font-size: inherit;
      color: #606266;
      background-color: #FFF;
      background-image: none;
      border: 1px solid #DCDFE6;
      border-radius: 4px;
      transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    }
    .big-text-contents{
      display: flex;
      align-items: center;
      margin: 0 0 20px 6px;
    }
      .big-text-contents div i{
        font-size: 24px;
        padding: 5px;
      }
      .checkbox-task-delet .update-i, .update-i {
        color: #0a68c0;
      }
      .el-icon-delete, .checkbox-task-delet .el-icon-delete {
        color: #F56C6C;
      }
      .el-icon-edit-outline:hover, .el-icon-delete:hover {
        cursor: pointer;
      }
      .mm-updating-back-color{
        background-color: #dee3e7;
      }
      .mm-line-through{
        text-decoration: line-through;
      }
      .mm-hover-show {
        display: none;
      }
      .mm-hover-parent{
        position: relative;
      }
      .mm-hover-parent:hover .mm-hover-show {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        padding: 3px;
        background: rgba(255, 255, 255, 0.9);
      }
      .mm-image-container{
        width: 100px; 
        height: 100px; 
        background-color: #dee3e7; 
        display: inline-block;
      }
      .mm-mini-additional-info{
        width: 100%;
        font-size: 15px;
        padding: 4px;
        flex-direction: row;
        flex-wrap: wrap
      }
      .mm-image_covered {
        background-color: #fdceb3;
      }
      /* Chat containers */
      .mm-mini-comment-container {
        position: relative;
        border: 2px solid #dedede;
        background-color: #f1f1f1;
        border-radius: 5px;
        padding: 3px 10px;
        margin: 10px 0;
      }
  
      /* Darker chat container */
      .darker {
        border-color: #ccc;
        background-color: #aebdc9;
      }
  
      /* Clear floats */
      .mm-mini-comment-container::after {
        content: "";
        clear: both;
        display: table;
      }
  
      /* Style time text */
      .time-right {
        float: right;
        color: #aaa;
      }
  
      /* Style time text */
      .time-left {
        float: left;
        color: #797676;
      }
      .mm-right-15 {
        margin-right: 15%; 
      }
      .mm-left-15 {
        margin-left: 15%; 
      }
      .mm-send-icon {
        font-size: 22px;
        cursor: pointer;
        margin-left: 10px;
        padding: 4px;
        border: 1px solid grey;
        border-radius: 15%;
        align-self: flex-end;
      }
      .mm-mini-comment-edit-icon {
        display: none;
      }
  
      .mm-mini-comment-container:hover .mm-mini-comment-edit-icon {
        position: absolute;
        font-size: 22px;
        top: 0;
        right: 0;
        display: flex;
        padding: 3px;
        color: #0a68c0;
        background-color: rgba(255, 255, 255, 0.7);
      }
      .mm-mini-task-comment {
        max-height: 400px;
        overflow-y: scroll; 
      }
      .mm-back-editing {
        background-color: #fc9744;
      }
  </style>
  